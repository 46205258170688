export interface MenuAnalyticsEvents {
  'Returning Customer Order Details': CustomerOrderDetailsEvent
  portion_swap: PortionSwapEvent
  create_halfhalf: CreateHalfHalfEvent
  'Banner Interaction': BannerInteractionEvent
  create_byo: CreateBYOEvent
  product_card_interaction: ProductCardInteractionEvent
  'Voucher Code Entered': VoucherCodeEntered
  'Voucher Code Processed': VoucherCodeDeclinedEvent | VoucherCodeAcceptedEvent
  'Voucher Code Removed': VoucherCodeRemoved
  add_to_cart: AnalyticsItemsParams
  remove_from_cart: AnalyticsItemsParams
}

export enum EventSourceType {
  InlineUpsell = 'inline_upsell',
  HardUpsell = 'hard_upsell',
  MenuItem = 'menu_item',
  ProductCard = 'product_card',
  ReplaceInCart = 'replace_in_cart',
}

interface CustomerOrderDetailsEvent {
  Action: string
}

interface PortionSwapEvent {
  parent_item_id: string
  swap_position: number
  swapped_in_item_id: string | undefined
  swapped_out_item_id: string | undefined
}

interface CreateHalfHalfEvent {
  from_item_id: string
  to_item_id: string
}

interface BannerInteractionEvent {
  event: string
  element_type: string
  element_id: string
  element_name: string
  element_source: string
  interaction_type: string
  items?: string
}

interface CreateBYOEvent {
  byo_type: 'halfhalf' | 'quattro'
  from_item_id: string
  to_item_id: string
}

interface ProductCardInteractionEvent {
  element_id: string
  interaction_type: string
}

interface AnalyticsItemsParams {
  items: SecurityProductParams[]
  event_source?: EventSourceType
}

interface VoucherCodeEntered {
  'Voucher Code': string
  'Voucher Code Source': VoucherCodeSource
}

interface VoucherCodeRemoved {
  'Voucher Code': string
  'Voucher Code Source': VoucherCodeSource
  'Voucher Value'?: number
}

interface VoucherCodeAcceptedEvent {
  'Voucher Code': string
  'Voucher Processing Status': VoucherProcessingStatus
  'Voucher Description'?: string
  'Voucher Value'?: number
}

export type VoucherCodeSource = 'Form' | 'Link' | 'Offers'
export type VoucherProcessingStatus = 'Accepted' | 'Unknown' | 'Expired' | 'Not valid'

export interface VoucherCodeDeclinedEvent {
  'Voucher Processing Status': VoucherProcessingStatus
  'Voucher Code'?: string
  'Voucher Code Source'?: VoucherCodeSource
  'Voucher Declined Reason'?: string
}
