import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import {
  DeliveryAddressSearchVersionType,
  EnhancedEcommercePurchaseEvent,
  useDeliveryAddressSearchVersion,
  useMenu,
  usePurchaseEvent,
} from '@dominos/hooks-and-hocs'
import { ApplicationConfiguration } from '@dominos/interfaces'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useSelector } from 'react-redux'
import { analytics, FeatureEventSender } from './analytics'
import { reportCheckout } from './report-checkout'
import { reportCustomer } from './report-customer'
import { reportError } from './report-error'
import { reportInteract } from './report-interact'
import { reportKiosk } from './report-kiosk'
import { reportPage } from './report-page'
import { reportPayment } from './report-payment'
import { reportProduct } from './report-product'
import { reportStore } from './report-store'
import { reportTracker } from './report-tracker'
import { reportVoucher } from './report-voucher'
import { getSecurityCommonParams, security, useSecurityContext } from './security'
import { reportStartJourneyOnMenu } from './report-start-journey-on-menu'
import { reportEventBanners } from './report-event-banners'
import { reportFeature } from './report-feature'
import { reportMyInvoiceIframeStatus } from './report-iframe-status'
import { reportExpressCheckout } from './report-express-checkout'
import useFeatureEvent from '../features/launch-darkly/use-feature-event'

export const report = (
  applicationInsights: ApplicationInsights | null,
  settings: ApplicationConfiguration,
  appStoredPurchaseEvent: EnhancedEcommercePurchaseEvent,
  sendFeatureEvent: FeatureEventSender,
  basket?: Basket,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
  menu?: Menu,
  deliveryAddressSearchVersion?: DeliveryAddressSearchVersionType,
) => {
  const { sendSecurity } = security(applicationInsights, getSecurityCommonParams(settings))
  const { sendAnalytics } = analytics()

  return {
    ...reportCustomer(sendSecurity, sendAnalytics),
    ...reportInteract(
      sendSecurity,
      sendAnalytics,
      settings,
      basket,
      menu,
      currentStore,
      currentOrder,
      deliveryAddressSearchVersion,
    ),
    ...reportKiosk(sendSecurity, currentOrder),
    ...reportCheckout(
      sendSecurity,
      sendAnalytics,
      sendFeatureEvent,
      appStoredPurchaseEvent,
      basket,
      currentStore,
      currentOrder,
    ),
    ...reportProduct(sendSecurity, sendAnalytics, sendFeatureEvent, currentOrder),
    ...reportPage(
      sendSecurity,
      sendAnalytics,
      settings,
      basket,
      menu,
      currentStore,
      currentOrder,
      deliveryAddressSearchVersion,
    ),
    ...reportError(sendSecurity, currentOrder),
    ...reportTracker(sendSecurity, currentOrder),
    ...reportStartJourneyOnMenu(sendSecurity, sendAnalytics, currentOrder),
    ...reportEventBanners(sendAnalytics),
    ...reportFeature(sendSecurity, currentOrder),
    ...reportMyInvoiceIframeStatus(sendSecurity),
    ...reportExpressCheckout(sendAnalytics),
    ...reportStore(sendSecurity, sendAnalytics, currentStore, currentOrder),
    ...reportVoucher(sendSecurity, sendAnalytics, basket, currentStore, currentOrder),
    ...reportPayment(sendSecurity, sendAnalytics, currentStore, currentOrder),
  }
}

export const useReport = () => {
  const { client } = useSecurityContext()
  const settings = useSelector((store: RootReducer) => store.applicationReducer)
  const { basket } = useSelector((store: RootReducer) => store.basketReducer)
  const { currentStore } = useSelector((store: RootReducer) => store.storeReducer)
  const currentOrder = useSelector((state: RootReducer) => state.currentOrderDetailsReducer)
  const { menu } = useMenu()
  const eventDetails = usePurchaseEvent()
  const { trackEvent: sendFeatureEvent } = useFeatureEvent()
  const { deliveryAddressSearchVersion } = useDeliveryAddressSearchVersion()

  return report(
    client,
    settings,
    eventDetails,
    sendFeatureEvent,
    basket,
    currentStore,
    currentOrder,
    menu,
    deliveryAddressSearchVersion,
  )
}
