import {
  ProductUpsellComponentType,
  ProductUpsellZoneId,
  UpsellType,
} from '@dominos/components/product/product-upsell/product-upsell.interface'

export const mapUpsellZoneIdToUpsellFilter = (zoneId: ProductUpsellZoneId) => {
  switch (zoneId) {
    case ProductUpsellZoneId.ProductBaseUpsellBanner:
      return {
        componentType: ProductUpsellComponentType.Crust,
        upsellType: UpsellType.Banner,
        allowedLayout: 'ProductList' as BffContext.Layouts,
        locationType: 'ProductDetails',
      }
    default:
      return undefined
  }
}
