import {
  ProductUpsellComponentType,
  ProductUpsellZoneId,
  UpsellData,
  UpsellType,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { useOffersContext } from '@dominos/hooks-and-hocs/offers'
import { productUpsellFilter } from './offers-helpers'
import { mapUpsellZoneIdToUpsellFilter } from './upsell-zone-mapper'
import { isOfferAvailable } from './utils'

export interface OfferAvailableFilters {
  componentType: ProductUpsellComponentType
  availableCodes: string[]
  selectedCodes: string[]
}

export interface ProductUpsellProps {
  zoneId: ProductUpsellZoneId
  availableCodes: string[]
  selectedCodes: string[]
}

const transformMedia = <TMedia>(media: Bff.Offers.OfferMedia, upsellType: UpsellType): TMedia => {
  switch (upsellType) {
    case UpsellType.Banner:
      return {
        name: {
          value: media.name,
        },
        description: {
          value: media.description,
        },
        image: {
          uri: media.banner.url,
          altText: media.banner.altText,
        },
      } as TMedia

    case UpsellType.Popup:
      return {
        name: {
          value: media.name,
        },
        description: {
          value: media.description,
        },
        image: {
          uri: media.popUp.url,
          altText: media.popUp.altText,
        },
      } as TMedia
  }
}

const createUpsellOffer = <TMedia>(
  upsellType: UpsellType,
  linkedItem: Bff.Offers.LinkedItem,
  media: Bff.Offers.OfferMedia,
  price: string,
  showBanner: boolean,
  showPopUp: boolean,
): UpsellData<TMedia> | undefined => {
  if ((showBanner && upsellType == 'Banner') || (showPopUp && upsellType == 'Popup')) {
    return {
      media: transformMedia<TMedia>(media, upsellType),
      price,
      code: linkedItem.itemCode,
    }
  }

  return undefined
}

export const useProductUpsell = <TMedia>({
  zoneId,
  availableCodes,
  selectedCodes,
}: ProductUpsellProps): UpsellData<TMedia>[] => {
  const { offers: allOffers = [] } = useOffersContext()

  const upsellFilter = mapUpsellZoneIdToUpsellFilter(zoneId)
  if (!upsellFilter) return []

  const filteredOffer = productUpsellFilter(allOffers, upsellFilter.locationType, upsellFilter.allowedLayout)
  const upsellOffers: UpsellData<TMedia>[] = []

  if (filteredOffer && filteredOffer.items.length > 0) {
    filteredOffer.items.forEach((item) => {
      const { media, locations } = filteredOffer
      const { linkedItem, price } = item
      const { componentType, upsellType } = upsellFilter

      if (
        isOfferAvailable(
          {
            componentType,
            availableCodes,
            selectedCodes,
          },
          linkedItem,
        )
      ) {
        const upsellBanner = createUpsellOffer<TMedia>(
          upsellType,
          linkedItem,
          media,
          price,
          locations[0].showBanner,
          locations[0].showPopUp,
        )
        if (upsellBanner) {
          upsellOffers.push(upsellBanner)
        }
      }
    })
  }

  return upsellOffers
}
