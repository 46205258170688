export const productDetailLocationType: string = 'ProductDetails'

export interface ProductUpsellData {
  media: UpsellMedia
  price: string | null | undefined
  code: string
  showBanner: boolean
  showPopUp: boolean
  componentType: string | null
  componentName: string
}

export interface UpsellData<TMedia> {
  media: TMedia
  price: string | null | undefined
  code: string
}

export enum UpsellType {
  Banner = 'Banner',
  Popup = 'Popup',
}

export interface BannerMedia {
  name: Bff.Common.TextMedia
  description?: Bff.Common.TextMedia | null
  image: Bff.Common.ImageMedia
}

export enum ProductUpsellComponentType {
  Crust = 'Crust',
  Sauce = 'Sauce',
  Topping = 'Topping',
}

export interface ProductUpsellProps extends BaseProductProps {
  currentProduct: BasketLineData | undefined
  productUpsellVariation?: ProductUpsellVariationTypes
  allowableComponentTypes?: string[]
  onUpsellSelected: (code: TouchableGridElement, componentType: string | null) => void
}

export enum ProductComponentUpsellLDVariation {
  VariationNone = 'variation-none', //Represents a variation that disable the upsell feature
  VariationEnable = 'variation-enable', // Represents a variation that enables the upsell feature. The action button is displayed without price text.
  VariationPriceEnable = 'variation-price-enable', // Represents a variation that enables the upsell feature. The action button is displayed with price text.
}

export enum ProductUpsellVariationTypes {
  VariationNone = 'variation-none',
  VariationBanner = 'variation-banner',
  VariationPopup = 'variation-popup',
}

export interface UpsellMedia {
  name: Bff.Common.TextMedia
  description?: Bff.Common.TextMedia | null
  popupImage: Bff.Common.ImageMedia
  bannerImage: Bff.Common.ImageMedia
}

export interface UpsellProps {
  productUpsellPopupDismissed?: boolean
}

export enum ProductUpsellZoneId {
  ProductBaseUpsellBanner = 'product_base_upsell_banner',
}

export interface ProductUpsellZones {
  zoneId: string
}
