import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { notifyNativeApp } from '@dominos/business/functions/native-app'
import { CollapsableTitledCard, Spinner } from '@dominos/components'
import { Breadcrumb, getBreadcrumbTitlesMyOrder } from '@dominos/components/breadcrumb'
import {
  useCheckoutMyDetails,
  useCurrentOrderDetails,
  useCustomer,
  useDevToggles,
  useFeatures,
  useLoyaltyMarketing,
  useReport,
} from '@dominos/hooks-and-hocs'
import { ServiceMethodDetailsForm } from './service-method-details-form'
import { DetailsContainerProps } from './details-container.interface'
import { mapCustomerOrderDetails } from './helpers'
import { Loyalty } from './loyalty'
import { MyDetailsForm } from './my-details-form'
import { VipOffers } from './vip-offers'
import styles from './details-container.less'
import { MyDetailsFormReadOnly } from './my-details-form/my-details-form-read-only'

export interface CustomerOrderDetails {
  name: string
  alternateName: string
  emailAddress: string
  mobileNumber: string
  loyaltyEnrolled: { isEnrolled: boolean }
}

const DetailsContainer = ({ testID, onComplete }: DetailsContainerProps) => {
  const { t } = useTranslation('checkout')
  const { reportDetailsPrefilled, reportRememberMyDetails } = useReport()
  const { isLoggedIn, isLoyaltyEnrolled } = useCustomer()
  const { basketHeaderData: currentOrderData } = useCurrentOrderDetails()
  const [vipOffersEnabled] = useFeatures('VipOffers')
  const { enrollCustomerInLoyaltyMarketing } = useLoyaltyMarketing()
  const [loyaltyEnrolled, setLoyaltyEnrolled] = useState<boolean>((isLoggedIn && isLoyaltyEnrolled) ?? false)
  const [eClub, setEClub] = useState<{ [key: string]: boolean }>({})
  const [receiveUpdatesAndOffers, setReceiveUpdatesAndOffers] = useState<boolean>(false)
  const {
    isMyDetailsValid,
    setIsMyDetailsValid,
    myDetails,
    rememberMyDetailsState,
    saveRememberMyDetailsCheck,
    handleMyDetailsChange,
    customerDetails,
    saveDetails,
  } = useCheckoutMyDetails()

  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const { isEnabled } = useDevToggles()
  const streamlinedCheckout = isEnabled['streamlined-checkout'] && isLoggedIn

  const [customerOrderDetails, setCustomerOrderDetails] = useState<CustomerOrderDetails>(() =>
    mapCustomerOrderDetails(myDetails, (isLoggedIn && isLoyaltyEnrolled) ?? false),
  )

  useEffect(() => {
    const customerOrderDetails = mapCustomerOrderDetails(myDetails, loyaltyEnrolled)
    setCustomerOrderDetails(customerOrderDetails)
  }, [myDetails, loyaltyEnrolled])

  useEffect(() => {
    notifyNativeApp('details')
    !!customerDetails && reportDetailsPrefilled()
  }, [])

  if (!currentOrderData) {
    return <Spinner testID='details-container-spinner' />
  }

  const handleEClubChange = (field: string | null, value: boolean) => {
    setEClub((prev) => ({ ...prev, [field!]: value }))
  }

  const handleComplete = () => {
    reportRememberMyDetails(rememberMyDetailsState)
    saveRememberMyDetailsCheck(rememberMyDetailsState)
    saveDetails(!isLoggedIn && rememberMyDetailsState && customerOrderDetails)

    if (receiveUpdatesAndOffers) {
      enrollCustomerInLoyaltyMarketing()
    }

    onComplete()
  }

  return (
    <div>
      <Breadcrumb
        testID='checkout.details.breadcrumb'
        entities={getBreadcrumbTitlesMyOrder(basketData.total, basketData.lines.length)}
        flowDepth={1}
        textNamespace={['checkout', 'basket']}
      />
      <Loyalty
        enrolled={loyaltyEnrolled}
        receiveUpdatesAndOffers={receiveUpdatesAndOffers}
        onCheck={setLoyaltyEnrolled}
        onCheckReceiveUpdates={setReceiveUpdatesAndOffers}
      />
      {!streamlinedCheckout ? (
        <CollapsableTitledCard
          primaryTitle={t('My Details')}
          startCollapsed={false}
          noPadding={true}
          testID={'checkout-container-card'}
        >
          <div className={styles.titledCardContent}>
            <MyDetailsForm
              testID='my-details-form'
              onFormValidationChange={setIsMyDetailsValid}
              initialDetails={customerDetails}
              initialRememberDetails={rememberMyDetailsState}
              onChange={handleMyDetailsChange}
            />
          </div>
          {vipOffersEnabled && <VipOffers onChange={handleEClubChange} />}
        </CollapsableTitledCard>
      ) : (
        <div className={styles.streamlinedCheckoutDetails}>
          <MyDetailsFormReadOnly
            initialDetails={customerDetails}
            setEditMyDetails={() => {}}
            testID={`${testID}.my-details-streamlined`}
            showDeliveryInfo={true}
          />
        </div>
      )}
      <ServiceMethodDetailsForm
        customerOrderDetails={customerOrderDetails}
        isMyDetailsValid={isMyDetailsValid}
        eClub={eClub}
        testID={testID}
        onComplete={handleComplete}
      />
    </div>
  )
}

export { DetailsContainer }
