import { rootActions } from '@dominos/business'
import { ProductUpsellConfirmation } from '@dominos/components/product/product-upsell/product-upsell-confirmation'
import { useReport } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export interface WithProductUpsellWrapperProps {
  onUpsellAccepted: (code: string, confirmationTitle: string) => void
  onUpsellDeclined: () => void
}

export interface WithProductUpsellWrapperOptions {
  persistDismissed?: boolean
  showConfirmation?: boolean
  enableTimeout?: boolean
}

const withProductUpsellWrapper = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithProductUpsellWrapperProps>,
) => {
  const HOC: React.FC<
    P & {
      onUpsellAccepted: (code: string, confirmationTitle: string) => void
      onUpsellDeclined: () => void
      zoneId?: string
      options?: WithProductUpsellWrapperOptions
    }
  > = ({ onUpsellAccepted, onUpsellDeclined, zoneId, options = {}, ...props }) => {
    const { persistDismissed = false, showConfirmation = false } = options
    const dispatch = useDispatch()
    const { t } = useTranslation('menu')
    const { reportProductUpsellAdded, reportProductUpsellRemoved } = useReport()
    const dismissedZones = useSelector((state: RootReducer) => state.productUpsellReducer.dismissedZones)
    const [isVisible, setIsVisible] = useState(false)
    const [confirmationState, setConfirmationState] = useState({
      hasConfirmationTriggered: false,
      confirmationTitle: '',
    })

    useEffect(() => {
      setIsVisible(true)
      //TODO: MAB-3871 Implement the timeout logic using enableTimeout para passed to the HOC
    }, [])

    const persistUpsellDismissed = (zoneId: string) => {
      dispatch(rootActions.upsellDismissed({ zoneId }))
    }

    const handleUpsellAccepted = (code: string, confirmationTitle: string) => {
      setIsVisible(false)

      if (showConfirmation) {
        setConfirmationState({
          hasConfirmationTriggered: true,
          confirmationTitle: confirmationTitle,
        })
      }

      reportProductUpsellAdded()

      onUpsellAccepted(code, confirmationTitle)
    }

    const handleUpsellDeclined = () => {
      setIsVisible(false)
      if (persistDismissed && zoneId) {
        persistUpsellDismissed(zoneId)
      }
      reportProductUpsellRemoved()
      onUpsellDeclined()
    }

    const hasDismissed = zoneId && dismissedZones.includes(zoneId)

    return (
      <>
        {isVisible && !hasDismissed && (
          <WrappedComponent
            {...(props as P)}
            onUpsellAccepted={handleUpsellAccepted}
            onUpsellDeclined={handleUpsellDeclined}
          />
        )}
        {confirmationState.hasConfirmationTriggered && (
          <ProductUpsellConfirmation
            testID='product-upsell-confirmation'
            title={confirmationState.confirmationTitle}
            description={t('ComponentUpsellConfirmationMessage', {
              defaultValue: 'Great! We have upgraded your item',
            })}
          />
        )}
      </>
    )
  }

  return HOC
}

export { withProductUpsellWrapper }
