import { getFullUrl } from '@dominos/business/functions'
import { BannerMedia, UpsellData } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { TFunction } from 'react-i18next'

export type ProductUpsellCardViewProps = {
  testID?: string
  viewModel: ProductUpsellCardViewModel
  onYesClicked: () => void
  onNoClicked: () => void
}

type ProductUpsellCardViewModelProps = {
  t: TFunction<'menu'>
  upsellData: UpsellData<BannerMedia>
}

type ProductUpsellCardViewModel = {
  upgradeButtonText: string
  bannerImageUrl: string
  upsellName: string
}

export const createProductUpsellCardViewModel = ({
  t,
  upsellData,
}: ProductUpsellCardViewModelProps): ProductUpsellCardViewModel => {
  const upgradeButtonText = upsellData.price ? upsellData.price : t('ProductComponentUpsellYes')
  const upsellName = upsellData.media.name.value
  const bannerImageUrl = getFullUrl(upsellData.media.image.uri)

  return { upgradeButtonText, bannerImageUrl, upsellName }
}
